<template>
  <VChart class="chart" :option="option" :update-options="{ notMerge: true }" />
</template>

<script setup>
  import { use } from 'echarts/core'
  import { CanvasRenderer } from 'echarts/renderers'
  import { BarChart } from 'echarts/charts'
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
  } from 'echarts/components'
  import VChart, { THEME_KEY } from 'vue-echarts'
  import { ref, defineProps, watch } from 'vue'
  import { useI18n } from 'vue-i18n'

  use([
    CanvasRenderer,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
  ])

  const { t } = useI18n()

  const props = defineProps({
    dealerBarPerType: {
      type: Object,
      default: {},
    },
  })

  // provide(THEME_KEY, 'dark')
  // var emphasisStyle = {
  //   itemStyle: {
  //     shadowBlur: 10,
  //     shadowColor: 'rgba(0,0,0,0.3)',
  //   },
  // }
  const option = ref({
    title: {
      text: t('DealerDashboard.statisticsPerType'),
      top: '5%',
      left: 'center',
    },
    // legend: {
    //   data: ['bar', 'bar2'],
    //   left: '10%',
    // },
    // brush: {
    //   toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
    //   xAxisIndex: 0,
    // },
    // toolbox: {
    //   feature: {
    //     magicType: {
    //       type: ['stack'],
    //     },
    //     dataView: {},
    //   },
    // },
    tooltip: {
      //formatter: '{a} <br/>{b} : {c} ({d}%)',
      formatter: function (params) {
        // console.log('-------- formatter')
        // console.log(params)
        // return `總量測 <br/> 2200 ${params.name}`
        return ``
      },
    },
    xAxis: {
      data: [
        t('DealerDashboard.bloodPressure'),
        t('DealerDashboard.bodyTemperature'),
        t('DealerDashboard.BMI'),
      ],
      name: '',
      axisLine: { onZero: true },
      splitLine: { show: false },
      splitArea: { show: false },
    },
    yAxis: {},
    grid: {
      bottom: 70,
    },
    series: [
      {
        name: 'bar', // 正常，綠色
        type: 'bar',
        stack: 'one',
        // emphasis: emphasisStyle,
        label: {
          show: true,
          position: 'inside',
        },
        itemStyle: { color: '#4DBAA7' },
        data: [],
      },
      {
        name: 'bar2', // 異常，紅色
        type: 'bar',
        stack: 'one',
        label: {
          show: true,
          position: 'inside',
        },
        itemStyle: { color: '#EB7777' },
        // emphasis: emphasisStyle,
        data: [],
      },
    ],
  })

  watch(
    () => props.dealerBarPerType,
    (current, prev) => {
      const normalData = []
      const abnormalData = []

      let found = current.find((element) => element.Type === 'Blood Pressure')
      let total = parseInt(found?.Total) | 0
      let abnormal = parseInt(found?.Abnormal) | 0
      normalData.push(total - abnormal)
      abnormalData.push(abnormal)

      found = current.find((element) => element.Type === 'Temperature')
      total = parseInt(found?.Total) | 0
      abnormal = parseInt(found?.Abnormal) | 0
      normalData.push(total - abnormal)
      abnormalData.push(abnormal)

      found = current.find((element) => element.Type === 'BMI')
      total = parseInt(found?.Total) | 0
      abnormal = parseInt(found?.Abnormal) | 0
      normalData.push(total - abnormal)
      abnormalData.push(abnormal)

      option.value = {
        title: {
          text: t('DealerDashboard.statisticsPerType'),
          top: '5%',
          left: 'center',
        },
        tooltip: {
          //formatter: '{a} <br/>{b} : {c} ({d}%)',
          formatter: function (params) {
            let totalCount = 0
            if (params.name === t('DealerDashboard.bloodPressure')) {
              totalCount =
                option.value.series[0].data[0] + option.value.series[1].data[0]
            } else if (params.name === t('DealerDashboard.bodyTemperature')) {
              totalCount =
                option.value.series[0].data[1] + option.value.series[1].data[1]
            } else if (params.name === t('DealerDashboard.BMI')) {
              totalCount =
                option.value.series[0].data[2] + option.value.series[1].data[2]
            }
            return `總量測 <br/> ${totalCount}`
          },
        },
        xAxis: {
          data: [
            t('DealerDashboard.bloodPressure'),
            t('DealerDashboard.bodyTemperature'),
            t('DealerDashboard.BMI'),
          ],
          name: '',
          axisLine: { onZero: true },
          splitLine: { show: false },
          splitArea: { show: false },
        },
        yAxis: {
          name: t('DealerDashboard.MeasureUnit'),
        },
        grid: {
          bottom: 70,
        },
        series: [
          {
            name: 'normalBar', // 正常，綠色
            type: 'bar',
            stack: 'one',
            // emphasis: emphasisStyle,
            label: {
              show: true,
              position: 'inside',
            },
            itemStyle: { color: '#4DBAA7' },
            data: normalData,
          },
          {
            name: 'abnormalBar', // 異常，紅色
            type: 'bar',
            stack: 'one',
            label: {
              show: true,
              position: 'inside',
            },
            itemStyle: { color: '#EB7777' },
            // emphasis: emphasisStyle,
            data: abnormalData,
          },
        ],
      }
    }
  )
</script>

<style scoped>
  .chart {
    height: 400px;
  }
</style>
