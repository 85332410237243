<template>
  <div class="dashboard-container">
    <div class="dashboard-top">
      <div class="dashboard-top-date-picker">
        <a-range-picker
          @change="onChange"
          :ranges="ranges"
          v-model:value="fromToDatelist"
          format="YYYY/MM/DD"
          @openChange="onDatePickerOpen"
        />
      </div>
    </div>
    <div class="dashboard-middle">
      <DashboardVisit
        :startTime="fromDate"
        :endTime="toDate"
        :refresh="refresh"
        :cleanUiFlag="cleanUiFlag"
      />
    </div>
    <div class="dashboard-bottom">
      <DashboardClassified
        :startTime="fromDate"
        :endTime="toDate"
        :refresh="refresh"
      />
    </div>
  </div>
</template>

<script>
  import DashboardVisit from './components/DashboardVisit'
  import DashboardClassified from './components/DashboardClassified'
  import dayjs from 'dayjs'
  import moment from 'moment'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Index',
    data() {
      const ranges = {}
      ranges[this.$t('DatePicker.today')] = [moment(), moment()]
      ranges[this.$t('DatePicker.yesterday')] = [
        moment().add(-1, 'days'),
        moment().add(-1, 'days'),
      ]
      ranges[this.$t('DatePicker.thisMonth')] = [
        moment().startOf('month'),
        moment().endOf('month'),
      ]
      ranges[this.$t('DatePicker.latestMonth')] = [
        moment().add(-1, 'months'),
        moment(),
      ]
      ranges[this.$t('DatePicker.latestThreeMonths')] = [
        moment().add(-3, 'months'),
        moment(),
      ]
      ranges[this.$t('DatePicker.latestYear')] = [
        moment().add(-1, 'years'),
        moment(),
      ]
      return {
        refresh: 0,
        cleanUiFlag: 0,
        fromDate: 0,
        toDate: 0,
        fromToDatelist: [],
        ranges,
        // ranges: {
        //   今天: [moment(), moment()],
        //   作天: [moment().add(-1, 'days'), moment().add(-1, 'days')],
        //   本月: [moment().startOf('month'), moment().endOf('month')],
        //   最近一個月: [moment().add(-1, 'months'), moment()],
        //   最近三個月: [moment().add(-3, 'months'), moment()],
        //   最近一年: [moment().add(-1, 'years'), moment()],
        // },
      }
    },
    components: { DashboardVisit, DashboardClassified },
    mounted() {
      if (this.fromToDatelist.length <= 0) {
        this.fromToDatelist.push(moment())
        this.fromToDatelist.push(moment())
        const fromDate = this.fromToDatelist[0].startOf('day').unix()
        const toDate = this.fromToDatelist[1].endOf('day').unix()
        this.fromDate = fromDate * 1000
        this.toDate = toDate * 1000
        this.refresh++
      }
    },
    computed: {
      ...mapGetters({
        title: 'user/title',
        role: 'user/role',
      }),
    },
    methods: {
      onChange(date, dateString) {
        console.log(date, dateString)
        if (dateString && dateString.length == 2) {
          const fromDate = dayjs(dateString[0]).startOf('day').unix()
          const toDate = dayjs(dateString[1]).endOf('day').unix()
          console.log(fromDate)
          console.log(toDate)
          this.fromDate = fromDate * 1000
          this.toDate = toDate * 1000
          this.refresh++
        }
      },
      onDatePickerOpen(status) {
        //console.log('onDatePickerOpen: ' + status)
        if (status === true) {
          this.cleanUiFlag++
        }
      },
    },
  }
</script>
<style lang="less" scoped>
  .dashboard-container {
    background-color: #e5e5e5;

    .dashboard-top {
      //margin-top: 9px;
      height: 40px;
      //background-color: #e5e5e5;
      .dashboard-top-date-picker {
        float: right;
        margin-top: 8px;
        margin-right: 84px;
        width: 274px;
      }
    }

    .dashboard-middle {
      padding-top: 14px;
      //margin-top: 14px;
      width: 1000px;
      margin: auto;
      display: flex;
      //justify-content: center;
      align-items: center;

      // display: flex;
      // align-items: center;
      // text-align: center;
    }
    .dashboard-bottom {
      //margin-top: 20px;
      padding-top: 14px;
      width: 1000px;
      margin: auto;
      display: flex;
      //justify-content: center;
      align-items: center;

      padding-bottom: 50px;
    }
  }
</style>
