<template>
  <VChart
    class="chart"
    :ref="pieChartRefName"
    :option="option"
    :update-options="{ notMerge: true }"
  />
</template>

<script setup>
  import { use } from 'echarts/core'
  import { CanvasRenderer } from 'echarts/renderers'
  import { PieChart } from 'echarts/charts'
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
  } from 'echarts/components'
  import VChart, { THEME_KEY } from 'vue-echarts'
  import { ref, defineProps, watch, onMounted, getCurrentInstance } from 'vue'
  import { useI18n } from 'vue-i18n'

  use([
    CanvasRenderer,
    PieChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
  ])

  const { t } = useI18n()

  const pieChartRefName = 'pieChart'
  // let pieChartDom // 用来存放当前页面对象的reName指定的Dom;

  // provide(THEME_KEY, 'dark')

  const props = defineProps({
    dealerAbnormalPie: {
      type: Object,
      default: {},
    },
  })

  // const props = withDefaults(defineProps(), {
  //   dealerAbnormalPie: {},
  // })

  const option = ref({
    title: {
      text: t('DealerDashboard.abnormalPercentage'),
      top: '5%',
      left: 'center',
    },
    tooltip: {
      trigger: 'none',
      // trigger: 'item',
      // formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    legend: {
      bottom: '5%',
      left: 'center',
      formatter: function (name) {
        let count = 0
        if (name === t('DealerDashboard.normalCount')) {
          count = 0
          return `{c|--}\n{b|${name}}`
        } else if (name === t('DealerDashboard.abnormalCount')) {
          count = 0
          return `{a|--}\n{b|${name}}`
        }
        return `{c|--}\n{b|${name}}`
      },
      textStyle: {
        rich: {
          a: {
            color: 'red',
            fontSize: 30,
            // lineHeight: 10,
          },
          b: {
            fontSize: 12,
            // height: 40,
          },
          c: {
            color: 'black',
            fontSize: 30,
            // height: 40,
          },
          // x: {
          //   fontSize: 18,
          //   fontFamily: 'Microsoft YaHei',
          //   borderColor: '#449933',
          //   borderRadius: 4,
          // },
        },
      },
      selectedMode: false,
      // data: ['量測異常人次', '量測正常人次'],
    },
    series: [
      {
        // name: 'Access From',
        type: 'pie',
        radius: ['40%', '60%'],
        avoidLabelOverlap: false,
        label: {
          formatter: '--',
          show: true,
          fontSize: 40,
          fontWeight: 'bold',
          color: 'red',
          position: 'center',
        },
        // emphasis: {
        //   label: {
        //     show: true,
        //     fontSize: 40,
        //     fontWeight: 'bold',
        //   },
        // },
        labelLine: {
          // show: false,
          length: 30,
        },
        silent: true,
        data: [
          {
            value: 0,
            name: t('DealerDashboard.abnormalCount'),
            itemStyle: { color: '#EB7777' },
          },
          {
            value: 0,
            name: t('DealerDashboard.normalCount'),
            itemStyle: { color: '#4DBAA7' },
          },
        ],
      },
    ],
  })

  // onMounted(() => {
  //   pieChartDom = getCurrentInstance().refs[pieChartRefName]
  // })

  watch(
    () => props.dealerAbnormalPie,
    (current, prev) => {
      const total = parseInt(current?.total) | 0
      const abnormal = parseInt(current?.abnormal) | 0
      const normal = total - abnormal

      option.value = {
        title: {
          text: t('DealerDashboard.abnormalPercentage'),
          top: '5%',
          left: 'center',
        },
        tooltip: {
          trigger: 'none',
          // trigger: 'item',
          // formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          bottom: '5%',
          left: 'center',
          formatter: function (name) {
            let count = 0
            if (name === t('DealerDashboard.normalCount')) {
              count = normal
              return count > 0
                ? `{c|${count}}\n{b|${name}}`
                : `{c|--}\n{b|${name}}`
            } else if (name === t('DealerDashboard.abnormalCount')) {
              count = abnormal
              return count > 0
                ? `{a|${count}}\n{b|${name}}`
                : `{a|--}\n{b|${name}}`
            }
            return `{c|--}\n{b|${name}}`
          },
          textStyle: {
            rich: {
              a: {
                color: 'red',
                fontSize: 30,
                // lineHeight: 10,
              },
              b: {
                fontSize: 12,
                // height: 40,
              },
              c: {
                color: 'black',
                fontSize: 30,
                // lineHeight: 10,
              },
              // x: {
              //   fontSize: 18,
              //   fontFamily: 'Microsoft YaHei',
              //   borderColor: '#449933',
              //   borderRadius: 4,
              // },
            },
          },
          selectedMode: false,
          // data: ['量測異常人次', '量測正常人次'],
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: ['40%', '60%'],
            avoidLabelOverlap: false,
            label: {
              formatter: function (params) {
                if (total === 0) {
                  return '--'
                } else {
                  return Math.floor((abnormal * 100) / total) + '%'
                }
              },
              show: true,
              fontSize: 40,
              fontWeight: 'bold',
              color: 'red',
              position: 'center',
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: 40,
            //     fontWeight: 'bold',
            //   },
            // },
            labelLine: {
              // show: false,
              length: 30,
            },
            silent: true,
            data: [
              {
                value: abnormal,
                name: t('DealerDashboard.abnormalCount'),
                itemStyle: { color: '#EB7777' },
              },
              {
                value: normal,
                name: t('DealerDashboard.normalCount'),
                itemStyle: { color: '#4DBAA7' },
              },
            ],
          },
        ],
      }
      // if (pieChartDom) {
      //   pieChartDom.setOption(option.value, true)
      // }
    }
  )
</script>

<style scoped>
  .chart {
    width: 390px;
    height: 360px;
  }
</style>
