import { render } from "./AbnormalPieChart.vue?vue&type=template&id=4a0f82a8&scoped=true"
import script from "./AbnormalPieChart.vue?vue&type=script&setup=true&lang=js"
export * from "./AbnormalPieChart.vue?vue&type=script&setup=true&lang=js"

import "./AbnormalPieChart.vue?vue&type=style&index=0&id=4a0f82a8&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-4a0f82a8"
/* hot reload */
if (module.hot) {
  script.__hmrId = "4a0f82a8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4a0f82a8', script)) {
    api.reload('4a0f82a8', script)
  }
  
  module.hot.accept("./AbnormalPieChart.vue?vue&type=template&id=4a0f82a8&scoped=true", () => {
    api.rerender('4a0f82a8', render)
  })

}

script.__file = "src/views/index/components/AbnormalPieChart.vue"

export default script