import { render } from "./DashboardClassified.vue?vue&type=template&id=b8107cb6&scoped=true"
import script from "./DashboardClassified.vue?vue&type=script&lang=js"
export * from "./DashboardClassified.vue?vue&type=script&lang=js"

import "./DashboardClassified.vue?vue&type=style&index=0&id=b8107cb6&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-b8107cb6"
/* hot reload */
if (module.hot) {
  script.__hmrId = "b8107cb6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('b8107cb6', script)) {
    api.reload('b8107cb6', script)
  }
  
  module.hot.accept("./DashboardClassified.vue?vue&type=template&id=b8107cb6&scoped=true", () => {
    api.rerender('b8107cb6', render)
  })

}

script.__file = "src/views/index/components/DashboardClassified.vue"

export default script