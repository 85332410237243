<template v-slot:fixed>
  <div class="dashboard-visit-container">
    <div class="title">{{ $t('MO001.countAndRatioTitle') }}</div>
    <div id="id-chart-circle" class="chart-circle">
      <div class="count-label">{{ totalCount }}</div>
      <div class="count-unit">{{ $t('MO001.countUnit') }}</div>
      <div class="circle-2"></div>
      <div class="circle-5"></div>
    </div>

    <span class="cell-1">
      <div class="sample-member-bar"></div>
      <div class="sample-member-word">{{ $t('MO001.member') }}</div>
      <div class="sample-member-count">
        {{ memberCount }} {{ $t('MO001.countUnit') }}
      </div>
      <div class="member-bar">
        <a-progress
          :percent="memberPercent"
          trailColor="#F1F1F1"
          strokeColor="#4EBAA8"
          :show-info="false"
        />
      </div>
      <div class="member-percent">{{ memberPercentWord }}</div>
      <div class="member-more" v-on:click="isShowGroup = !isShowGroup">
        {{ $t('MO001.more') }}
      </div>
    </span>

    <div class="cell-line-1"></div>

    <span class="cell-2">
      <div class="sample-identified-bar"></div>
      <div class="sample-identified-word">{{ $t('MO001.identified') }}</div>
      <div class="sample-identified-count">
        {{ identifiedCount }} {{ $t('MO001.countUnit') }}
      </div>
      <div class="identified-bar">
        <a-progress
          :percent="identifiedPercent"
          trailColor="#F1F1F1"
          strokeColor="#4EBAA8"
          :show-info="false"
        />
      </div>
      <div class="identified-percent">{{ identifiedPercentWord }}</div>
    </span>

    <div class="cell-line-2"></div>

    <span class="cell-3">
      <div class="sample-guest-bar"></div>
      <div class="sample-guest-word">{{ $t('MO001.guest') }}</div>
      <div class="sample-guest-count">
        {{ guestCount }} {{ $t('MO001.countUnit') }}
      </div>
      <div class="guest-bar">
        <a-progress
          :percent="guestPercent"
          trailColor="#F1F1F1"
          strokeColor="#4EBAA8"
          :show-info="false"
        />
      </div>
      <div class="geust-percent">{{ guestPercentWord }}</div>
    </span>

    <div v-if="isShowGroup" class="group-status-container">
      <div class="group-status-title">{{ $t('MO001.eachGroupCount') }}</div>
      <div class="group-status-close" v-on:click="isShowGroup = !isShowGroup">
        <CloseOutlined />
      </div>
      <a-list
        class="group-status-list"
        item-layout="horizontal"
        :data-source="groupData"
      >
        <template #renderItem="{ item }">
          <a-list-item class="group-status-list-item">
            <div class="group-status-list-item-name">
              {{ getGroupName(item.name) }}
            </div>
            <div class="group-status-list-item-progress">
              <a-progress
                :percent="item.percentage"
                size="small"
                trailColor="#F1F1F1"
                strokeColor="#4EBAA8"
                :show-info="false"
              />
            </div>
            <div class="group-status-list-item-percentage">
              {{ item.percentageWord }}
            </div>
          </a-list-item>
        </template>
      </a-list>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import { CloseOutlined } from '@ant-design/icons-vue'

  export default {
    data() {
      return {
        totalCount: 0,
        memberCount: 0,
        memberPercent: 0,
        memberPercentWord: '0%',
        identifiedCount: 0,
        identifiedPercent: 0,
        identifiedPercentWord: '0%',
        guestCount: 0,
        guestPercent: 0,
        guestPercentWord: '0%',
        groupData: [],
        isShowGroup: false,
      }
    },
    components: {
      CloseOutlined,
    },
    computed: {
      ...mapGetters({
        measuredVisits: 'statistics/measuredVisits',
      }),
    },
    props: {
      startTime: {
        type: Number,
        required: true,
      },
      endTime: {
        type: Number,
        required: true,
      },
      refresh: {
        type: Number,
        required: true,
        default: 0,
      },
      cleanUiFlag: {
        type: Number,
        default: 0,
      },
    },
    mounted() {
      const params = {
        startTime: this.startTime,
        endTime: this.endTime,
      }
      if (this.startTime > 0 && this.endTime > 0) {
        this.refreshMeasuredVisits(params)
      }
    },
    methods: {
      ...mapActions({
        refreshMeasuredVisits: 'statistics/refreshMeasuredVisits',
      }),
      parseData() {
        this.resetData()
        if (!this.measuredVisits) {
          return
        }
        if (this.measuredVisits.ok == false) {
          return
        }
        this.totalCount = this.measuredVisits.amount || 0
        this.measuredVisits.data.forEach((dataSet) => {
          if (dataSet && dataSet.type === 'member') {
            this.memberCount = dataSet.amount
            this.memberPercent = dataSet.percentage
            this.memberPercentWord = dataSet.percentage + '%'
            if (dataSet.groups) {
              dataSet.groups.forEach((groupData) => {
                this.groupData.push({
                  name: groupData.name,
                  percentage: groupData.percentage,
                  percentageWord: groupData.percentage + '%',
                })
              })
            }
          } else if (dataSet && dataSet.type === 'hasIDs') {
            this.identifiedCount = dataSet.amount
            this.identifiedPercent = dataSet.percentage
            this.identifiedPercentWord = dataSet.percentage + '%'
          } else if (dataSet && dataSet.type === 'guest') {
            this.guestCount = dataSet.amount
            this.guestPercent = dataSet.percentage
            this.guestPercentWord = dataSet.percentage + '%'
          }
        })
      },
      resetData() {
        this.totalCount = 0
        this.memberCount = 0
        this.memberPercent = 0
        this.memberPercentWord = '0%'
        this.identifiedCount = 0
        this.identifiedPercent = 0
        this.identifiedPercentWord = '0%'
        this.guestCount = 0
        this.guestPercent = 0
        this.guestPercentWrod = '0%'
        this.groupData = []
      },
      getGroupName(name) {
        if (name === 'widespread') {
          return this.$t('MO001.ungrouped')
        } else {
          return name
        }
      },
    },
    watch: {
      refresh: function () {
        const params = {
          startTime: this.startTime,
          endTime: this.endTime,
        }
        if (this.startTime > 0 && this.endTime > 0) {
          this.refreshMeasuredVisits(params)
        }
      },
      cleanUiFlag: function () {
        if (this.cleanUiFlag > 0) {
          this.isShowGroup = false
        }
      },
      // endTime: function () {
      //   const params = {
      //     startTime: this.startTime,
      //     endTime: this.endTime,
      //   }
      //   if (this.startTime > 0 && this.endTime > 0) {
      //     this.refreshMeasuredVisits(params)
      //   }
      // },
      measuredVisits: function () {
        this.parseData()
      },
    },
  }
</script>
<style lang="less" scoped>
  .dashboard-visit-container {
    border-radius: 25px;
    background-color: #ffffff;
    margin-left: 23px;
    margin-right: 23px;
    width: 1000px;
    height: 450px;
    position: relative;

    .title {
      position: absolute;
      width: 100%;
      height: 50px;
      top: 27px;
      left: 0px;
      //bottom: 373px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 44px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      /* Gray 1 */

      color: #333333;
    }

    .chart-circle {
      background-image: url('~@/assets/image/circle-ellipse4.png');
      background-repeat: no-repeat;
      width: 255px;
      height: 255px;
      top: 118px;
      left: 66px;
      bottom: 77px;
      position: absolute;

      .count-label {
        width: 122px;
        height: 46px;
        position: absolute;
        top: 103px;
        left: 67px;
        bottom: 106px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 46px;
        /* identical to box height, or 96% */

        text-align: center;

        color: #707170;
      }

      .count-unit {
        width: 16px;
        height: 24px;
        position: absolute;
        top: 154px;
        left: 120px;
        bottom: 77px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        text-align: center;

        color: #7a7a7a;
      }

      .circle-2 {
        background-image: url('~@/assets/image/circle-ellipse2.png');
        width: 38px;
        height: 27px;
        position: absolute;
        bottom: 0;
        right: 120px;
      }

      .circle-5 {
        background-image: url('~@/assets/image/circle-ellipse5.png');
        width: 70px;
        height: 48px;
        position: absolute;
        bottom: 0;
        right: 40px;
      }
    }

    .cell-1 {
      .sample-member-bar {
        position: absolute;
        width: 30px;
        height: 4px;
        top: 137px;
        left: 377px;
        //bottom: 309px;
        background: #4ebaa8;
      }

      .sample-member-word {
        position: absolute;
        width: 36px;
        height: 22px;
        top: 128px;
        left: 422px;
        //bottom: 300px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height, or 122% */

        color: #969696;
      }

      .sample-member-count {
        position: absolute;
        width: 120px;
        height: 40px;
        top: 154px;
        left: 422px;
        //bottom: 274px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height, or 122% */

        color: #969696;
      }

      .member-bar {
        position: absolute;
        width: 190px;
        height: 22px;
        top: 138px;
        left: 581px;
        //bottom: 274px;
        //background: #f1f1f1;
      }

      .member-percent {
        position: absolute;
        width: 60px;
        height: 24px;
        top: 136px;
        left: 790px;
        //bottom: 290px;

        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height */

        text-align: center;

        color: #969696;
      }

      .member-more {
        position: absolute;
        width: 59px;
        height: 23px;
        top: 136px;
        left: 861px;
        cursor: pointer;
        //bottom: 290px;
        border: 1px solid #4ebaa8;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        //display: flex;
        align-items: center;
        text-align: center;
        vertical-align: middle;

        color: #4dbaa7;
      }
    }

    .cell-line-1 {
      position: absolute;
      width: 583px;
      height: 0px;
      top: 194px;
      left: 352px;
      border: 0.5px solid #c2c9d1;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }
    .cell-2 {
      .sample-identified-bar {
        position: absolute;
        width: 30px;
        height: 4px;
        top: 228px;
        left: 377px;
        //bottom: 309px;
        background: #4ebaa8;
      }

      .sample-identified-word {
        position: absolute;
        width: 150px;
        height: 22px;
        top: 219px;
        left: 422px;
        //bottom: 300px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height, or 122% */

        color: #969696;
      }

      .sample-identified-count {
        position: absolute;
        width: 120px;
        height: 40px;
        top: 245px;
        left: 422px;
        //bottom: 274px;
        font-family: Arial;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #969696;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 4px 0px;
      }

      .identified-bar {
        position: absolute;
        width: 190px;
        height: 22px;
        top: 231px;
        left: 581px;
        //bottom: 274px;
        //background: #f1f1f1;
      }

      .identified-percent {
        position: absolute;
        width: 60px;
        height: 24px;
        top: 231px;
        left: 790px;
        //bottom: 290px;

        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height */

        text-align: center;

        color: #969696;
      }
    }
    .cell-line-2 {
      position: absolute;
      width: 583px;
      height: 0px;
      top: 291px;
      left: 352px;
      border: 0.5px solid #c2c9d1;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }

    .cell-3 {
      .sample-guest-bar {
        position: absolute;
        width: 30px;
        height: 4px;
        top: 325px;
        left: 377px;
        //bottom: 309px;
        background: #dcf1ee;
      }

      .sample-guest-word {
        position: absolute;
        width: 200px;
        height: 22px;
        top: 316px;
        left: 422px;
        //bottom: 300px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #969696;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 4px 0px;
      }

      .sample-guest-count {
        position: absolute;
        width: 120px;
        height: 40px;
        top: 342px;
        left: 422px;
        //bottom: 274px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height, or 122% */

        color: #969696;
      }

      .guest-bar {
        position: absolute;
        width: 190px;
        height: 22px;
        top: 330px;
        left: 581px;
        //bottom: 274px;
        //background: #f1f1f1;
      }

      .geust-percent {
        position: absolute;
        width: 60px;
        height: 24px;
        top: 326px;
        left: 790px;
        //bottom: 290px;

        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height */

        text-align: center;

        color: #969696;
      }
    }
  }
  .group-status-container {
    width: 380px;
    //height: 242px;
    background: #fdfdff;
    position: absolute;
    top: 135px;
    left: 549px;
    /* stroke */

    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 13px;

    .group-status-title {
      // position: absolute;
      // top: 16px;
      // left: 119px;
      // width: 90px;
      margin-top: 16px;
      margin-left: 119px;
      margin-bottom: 10px;
      font-family: Noto Sans TC;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      display: flex;
      align-items: center;
      text-align: center;

      /* text|dark */

      color: #4f4f4f;
    }

    .group-status-close {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 10px;
      left: 320px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 1000;
    }

    .group-status-list {
      border: 1px solid #ebebeb;
      box-sizing: border-box;
      background: #fdfdff;
      border-radius: 13px;
      z-index: 9999;
      //z-index: 100;
      .group-status-list-item {
        background: #fdfdff;
        .group-status-list-item-name {
          margin-left: 30px;
        }
        .group-status-list-item-progress {
          width: 190px;
        }
        .group-status-list-item-percentage {
          margin-right: 30px;
        }
      }
    }
  }
</style>
