import { render } from "./StatisticsBarChart.vue?vue&type=template&id=5f1f59e0&scoped=true"
import script from "./StatisticsBarChart.vue?vue&type=script&setup=true&lang=js"
export * from "./StatisticsBarChart.vue?vue&type=script&setup=true&lang=js"

import "./StatisticsBarChart.vue?vue&type=style&index=0&id=5f1f59e0&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-5f1f59e0"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5f1f59e0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5f1f59e0', script)) {
    api.reload('5f1f59e0', script)
  }
  
  module.hot.accept("./StatisticsBarChart.vue?vue&type=template&id=5f1f59e0&scoped=true", () => {
    api.rerender('5f1f59e0', render)
  })

}

script.__file = "src/views/index/components/StatisticsBarChart.vue"

export default script