import { render } from "./DashboardVisit.vue?vue&type=template&id=7abaf0ae&scoped=true"
import script from "./DashboardVisit.vue?vue&type=script&lang=js"
export * from "./DashboardVisit.vue?vue&type=script&lang=js"

import "./DashboardVisit.vue?vue&type=style&index=0&id=7abaf0ae&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-7abaf0ae"
/* hot reload */
if (module.hot) {
  script.__hmrId = "7abaf0ae"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7abaf0ae', script)) {
    api.reload('7abaf0ae', script)
  }
  
  module.hot.accept("./DashboardVisit.vue?vue&type=template&id=7abaf0ae&scoped=true", () => {
    api.rerender('7abaf0ae', render)
  })

}

script.__file = "src/views/index/components/DashboardVisit.vue"

export default script