<template>
  <div class="dashboard-classified-container">
    <div class="title-adn-option">
      <div class="title">{{ $t('MO001.itemAndStatisticTitle') }}</div>
      <div class="options">
        <a-select v-model:value="subjectType" style="width: 100%">
          <a-select-option value="all">{{ $t('MO001.all') }}</a-select-option>
          <a-select-option value="member">
            {{ $t('MO001.member') }}
          </a-select-option>
          <a-select-option value="hasIDs">
            {{ $t('MO001.identified') }}
          </a-select-option>
          <a-select-option value="guest">
            {{ $t('MO001.guest') }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="bar-area">
      <div id="id-chart-bar" class="chart-bar"></div>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import * as echarts from 'echarts'

  export default {
    data() {
      return {
        chart: null,
        subjectType: 'all',
      }
    },
    props: {
      startTime: {
        type: Number,
        required: true,
        default: 0,
      },
      endTime: {
        type: Number,
        required: true,
        default: 0,
      },
      refresh: {
        type: Number,
        required: true,
        default: 0,
      },
    },
    computed: {
      ...mapGetters({
        measuredData: 'statistics/measuredClassifiedAbnormal',
      }),
    },
    mounted() {
      const params = {
        subjectType: this.subjectType,
        startTime: this.startTime,
        endTime: this.endTime,
      }
      if (this.startTime > 0 && this.endTime > 0) {
        this.refreshMeasuredClassifiedAbnormal(params)
      }
      this.createChart()
      this.updateChart()
    },
    methods: {
      ...mapActions({
        refreshMeasuredClassifiedAbnormal:
          'statistics/refreshMeasuredClassifiedAbnormal',
      }),
      createChart() {
        this.chart = echarts.init(document.getElementById('id-chart-bar'))
      },
      updateChart() {
        const option = {
          legend: {
            selectedMode: false,
          },
          tooltip: {},
          dataset: {
            source: [['product', this.$t('MO001.totolCount')]],
          },
          xAxis: { type: 'category' },
          yAxis: { name: this.$t('MO001.countUnitCapital') },
          series: [{ type: 'bar' }],
          //series: [{ type: 'bar' }, { type: 'bar' }],
        }

        if (this.measuredData) {
          this.measuredData.forEach((item) => {
            if (item.type === 'BPMeasurement') {
              option.dataset.source.push([
                this.$t('MO001.bloodPressureCapital'),
                item.amount,
                //item.abnormal,
              ])
            } else if (item.type === 'GlucoseMeasurement') {
              option.dataset.source.push([
                this.$t('MO001.glucoseCapital'),
                item.amount,
                //item.abnormal,
              ])
            } else if (item.type === 'BMI') {
              option.dataset.source.push([
                this.$t('MO001.bodyWeightCapital'),
                item.amount,
                //item.abnormal,
              ])
            } else if (item.type === 'OxygenSatMeasurement') {
              option.dataset.source.push([
                this.$t('MO001.spo2Capital'),
                item.amount,
                //item.abnormal,
              ])
            } else if (item.type === 'BodyTempMeasurement') {
              option.dataset.source.push([
                this.$t('MO001.bodyTemperatureCapital'),
                item.amount,
                //item.abnormal,
              ])
            } else if (item.type === 'StatureWeight') {
              option.dataset.source.push([
                this.$t('MO001.height'),
                item.amount,
                //item.abnormal,
              ])
            }
          })
        }

        this.chart.setOption(option)
      },
    },
    watch: {
      refresh: function () {
        const params = {
          subjectType: this.subjectType,
          startTime: this.startTime,
          endTime: this.endTime,
        }
        this.refreshMeasuredClassifiedAbnormal(params)
      },
      subjectType: function () {
        const params = {
          subjectType: this.subjectType,
          startTime: this.startTime,
          endTime: this.endTime,
        }
        this.refreshMeasuredClassifiedAbnormal(params)
      },
      measuredData: function () {
        this.updateChart()
      },
    },
  }
</script>
<style lang="less" scoped>
  .dashboard-classified-container {
    border-radius: 25px;
    background-color: #ffffff;
    margin-left: 23px;
    margin-right: 23px;
    width: 1000px;
    height: 413px;
    position: relative;

    .title-adn-option {
      height: 90px;
      .title {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 23px;
        left: 0px;
        //bottom: 373px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 44px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        /* Gray 1 */

        color: #333333;
      }
      .options {
        position: absolute;
        width: 150px;
        height: 30px;
        top: 31px;
        right: 75px;
      }
    }
    .bar-area {
      padding-left: 50px;
      .chart-bar {
        width: 900px;
        height: 323px;
      }
    }
  }
</style>
