import { render } from "./DealerLayout.vue?vue&type=template&id=2baa647a&scoped=true"
import script from "./DealerLayout.vue?vue&type=script&lang=js"
export * from "./DealerLayout.vue?vue&type=script&lang=js"

import "./DealerLayout.vue?vue&type=style&index=0&id=2baa647a&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-2baa647a"
/* hot reload */
if (module.hot) {
  script.__hmrId = "2baa647a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2baa647a', script)) {
    api.reload('2baa647a', script)
  }
  
  module.hot.accept("./DealerLayout.vue?vue&type=template&id=2baa647a&scoped=true", () => {
    api.rerender('2baa647a', render)
  })

}

script.__file = "src/views/index/DealerLayout.vue"

export default script